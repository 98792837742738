import React, { Fragment, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Form, Row, Spinner } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import {
    ApplianceDeactivationReason,
    ApplianceMaintenanceScheduleDto,
    EnableApplianceAfterwards,
    ManageApplianceStore,
    RepeatType,
} from '../../store/manage-appliances/manageApplianceReducer';
import {
    createMaintenanceSchedule,
    hoursToMillis,
    MILLIS_PER_HOUR,
    resetActionErrors,
    updateMaintenanceSchedule,
} from '../../store/manage-appliances/manageApplianceActions';
import DatePicker from 'react-datepicker';
import { renderDeactivationReasonOptions } from './ManageApplianceForm';

interface Props {
    manageApplianceStore: ManageApplianceStore;
    data: {
        applianceId: number;
        showLintFilterSetting: boolean;
        oldSchedule?: ApplianceMaintenanceScheduleDto;
    };
    close: () => void;
    createMaintenanceSchedule: (
        data: ApplianceMaintenanceScheduleDto,
        onSuccess: () => void
    ) => void;
    updateMaintenanceSchedule: (
        data: ApplianceMaintenanceScheduleDto,
        onSuccess: () => void
    ) => void;
    resetActionErrors: () => void;
}

interface InputFormData {
    nextStart: Date;
    durationHours: string;
    repeatInterval: string;
    monthlyMinimumDay: string;
    enableApplianceAfterwards: EnableApplianceAfterwards;
    lintFilterReplacement?: boolean;
    comment: string;
    reason: ApplianceDeactivationReason;
}

interface RepeatInterval {
    type: RepeatType;
    multiplier?: number;
}

const DEFAULT_REPEAT_INTERVAL: RepeatInterval = {
    type: RepeatType.NONE,
    multiplier: undefined,
};

function requiresMonthlyMinimumDay(repeatType: RepeatType) {
    return repeatType === RepeatType.MONTHLY_SAME_WEEKDAY;
}

function getCompatibleMonthlyMinimumDays(nextStart: Date): number[] {
    const dayOfNextStart = nextStart.getDate();
    const result: number[] = [];
    for (
        let day = Math.max(dayOfNextStart - 6, 1);
        day <= dayOfNextStart;
        day++
    ) {
        result.push(day);
    }
    return result;
}

function getDefaultMonthlyMinimumDay(nextStart: Date): number {
    const compatibleDays = getCompatibleMonthlyMinimumDays(nextStart);
    for (let i = 0; i < compatibleDays.length; i++) {
        if (compatibleDays[i] % 7 === 1) {
            return compatibleDays[i];
        }
    }
    return compatibleDays[0];
}

export function formatDurationAsHours(millis: number): string {
    const hours = millis / MILLIS_PER_HOUR;
    return Number.isInteger(hours) ? String(hours) : hours.toFixed(1);
}

export function formatRepeatInterval(
    repeatType: RepeatType,
    repeatMultiplier?: number
): string {
    if (repeatType === RepeatType.NONE) {
        return 'Einmalig';
    } else if (repeatType === RepeatType.WEEKLY) {
        if (!repeatMultiplier || Math.round(repeatMultiplier) <= 1) {
            return 'Wöchentlich';
        } else {
            return `Alle ${Math.round(repeatMultiplier)} Wochen`;
        }
    } else if (repeatType === RepeatType.MONTHLY_SAME_WEEKDAY) {
        if (!repeatMultiplier || Math.round(repeatMultiplier) <= 1) {
            return 'Monatlich';
        } else {
            return `Alle ${Math.round(repeatMultiplier)} Monate`;
        }
    }
    return `${repeatType} ${repeatMultiplier}`;
}

function parseRepeatInterval(
    displayString: string,
    options: RepeatInterval[]
): RepeatInterval {
    for (const option of options) {
        if (
            displayString ===
            formatRepeatInterval(option.type, option.multiplier)
        ) {
            return option;
        }
    }
    return DEFAULT_REPEAT_INTERVAL;
}

function formatMonthlyMinimumDay(dayOfMonth: number, dayOfWeek: string) {
    const firstClause = `nächster ${dayOfWeek} ab dem ${dayOfMonth}.`;

    if (dayOfMonth >= 25) {
        return `${firstClause} (letzter ${dayOfWeek})`;
    }

    switch (dayOfMonth) {
        case 1:
            return `${firstClause} (erster ${dayOfWeek})`;
        case 8:
            return `${firstClause} (zweiter ${dayOfWeek})`;
        case 15:
            return `${firstClause} (dritter ${dayOfWeek})`;
        case 22:
            return `${firstClause} (vierter ${dayOfWeek})`;
        default:
            return firstClause;
    }
}

function translateEnableApplianceAfterwards(
    input: EnableApplianceAfterwards
): string {
    switch (input) {
        case EnableApplianceAfterwards.YES:
            return 'Aktivieren';
        case EnableApplianceAfterwards.NO:
            return 'Deaktivieren';
        case EnableApplianceAfterwards.RESTORE_PREVIOUS_STATE:
            return 'Vorigen Zustand wiederherstellen';
        default:
            return `${input}`;
    }
}
function UpsertMaintenanceSchedule({
    manageApplianceStore,
    data,
    close,
    createMaintenanceSchedule,
    updateMaintenanceSchedule,
    resetActionErrors,
}: Props) {
    const isLoading = manageApplianceStore.loading;
    const createNewSchedule = !data.oldSchedule;

    useEffect(() => {
        resetActionErrors();
    }, []);

    const initialValues: InputFormData = getInitialValues();
    const repeatIntervalOptions: RepeatInterval[] = getRepeatIntervalOptions();

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Wartungszeitplan für Machine {data.applianceId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
                <ErrorFeedback apiErrors={manageApplianceStore.actionErrors} />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<InputFormData>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group>
                    <Form.Label>Nächster Start</Form.Label>
                    <DatePicker
                        showTimeSelect
                        dateFormat="dd.MM.yyyy, HH:mm"
                        selected={formProps.values.nextStart}
                        onChange={(val) => {
                            if (val) {
                                formProps.setFieldValue('nextStart', val);
                                formProps.setFieldValue(
                                    'monthlyMinimumDay',
                                    getDefaultMonthlyMinimumDay(val)
                                );
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group controlId="durationHours">
                    <Form.Label>Dauer (Stunden)</Form.Label>
                    <Form.Control
                        type="number"
                        onChange={formProps.handleChange}
                        value={formProps.values.durationHours}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Ausführung</Form.Label>
                    <Form.Control
                        as={'select'}
                        name={'repeatInterval'}
                        onChange={formProps.handleChange}
                        value={formProps.values.repeatInterval}
                    >
                        {repeatIntervalOptions
                            .map((it) =>
                                formatRepeatInterval(it.type, it.multiplier)
                            )
                            .map((it) => (
                                <option value={it} key={it}>
                                    {it}
                                </option>
                            ))}
                    </Form.Control>
                </Form.Group>
                {maybeRenderRepeatIntervalDetails(formProps)}
                <Form.Group>
                    <Form.Label>Danach aktivieren</Form.Label>
                    <Form.Control
                        as={'select'}
                        name={'enableApplianceAfterwards'}
                        onChange={formProps.handleChange}
                        value={formProps.values.enableApplianceAfterwards}
                    >
                        {Object.values(EnableApplianceAfterwards).map((it) => (
                            <option value={it} key={it}>
                                {translateEnableApplianceAfterwards(it)}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="comment">
                    <Form.Label>Kommentar</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={formProps.handleChange}
                        value={formProps.values.comment}
                    />
                </Form.Group>
                <Form.Group controlId="reason">
                    <Form.Label>Grund</Form.Label>
                    <Form.Control
                        as={'select'}
                        onChange={formProps.handleChange}
                        value={formProps.values.reason}
                    >
                        {renderDeactivationReasonOptions()}
                    </Form.Control>
                </Form.Group>
                {data.showLintFilterSetting && (
                    <Form.Group controlId="lintFilterReplacement_maintenanceSchedule">
                        <Form.Check
                            custom
                            type="checkbox"
                            name="lintFilterReplacement"
                            checked={!!formProps.values.lintFilterReplacement}
                            label="Filter Austausch planen"
                            onChange={formProps.handleChange}
                        />
                    </Form.Group>
                )}
                <Row className={'mx-0 mt-4'}>
                    <Button
                        onClick={handleCancel}
                        variant="outline-primary"
                        className={'mr-2'}
                    >
                        Abbrechen
                    </Button>
                    <Button disabled={isLoading} type="submit">
                        {isLoading && <Spinner animation={'border'} />}
                        {createNewSchedule ? 'Erstellen' : 'Updaten'}
                    </Button>
                </Row>
            </Form>
        );
    }

    function maybeRenderRepeatIntervalDetails(
        formProps: FormikProps<InputFormData>
    ) {
        const repeatInterval = parseRepeatInterval(
            formProps.values.repeatInterval,
            repeatIntervalOptions
        );
        if (!requiresMonthlyMinimumDay(repeatInterval.type)) {
            return null;
        }
        const nextStart = formProps.values.nextStart;
        const dayOfWeek = nextStart.toLocaleDateString('de-de', {
            weekday: 'long',
        });
        return (
            <Form.Group>
                <Form.Label>Ausführungsdetails</Form.Label>
                <Form.Control
                    as={'select'}
                    name={'monthlyMinimumDay'}
                    onChange={formProps.handleChange}
                    value={String(formProps.values.monthlyMinimumDay)}
                >
                    {getCompatibleMonthlyMinimumDays(nextStart).map((it) => (
                        <option value={String(it)} key={String(it)}>
                            {formatMonthlyMinimumDay(it, dayOfWeek)}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        );
    }

    function handleCancel() {
        close();
    }

    function handleSubmit(
        values: InputFormData,
        actions: FormikActions<InputFormData>
    ) {
        const scheduleDto = convertFormValues(values);
        createNewSchedule
            ? createMaintenanceSchedule(scheduleDto, close)
            : updateMaintenanceSchedule(scheduleDto, close);
        actions.setSubmitting(false);
    }

    function getInitialValues(): InputFormData {
        if (data.oldSchedule) {
            const nextStart = new Date(data.oldSchedule.next_start);
            return {
                nextStart: nextStart,
                durationHours: formatDurationAsHours(data.oldSchedule.duration),
                repeatInterval: formatRepeatInterval(
                    data.oldSchedule.repeat_type,
                    data.oldSchedule.repeat_multiplier
                ),
                monthlyMinimumDay: String(
                    data.oldSchedule.monthly_minimum_day ||
                        getDefaultMonthlyMinimumDay(nextStart)
                ),
                enableApplianceAfterwards:
                    data.oldSchedule.enable_appliance_afterwards,
                lintFilterReplacement: data.showLintFilterSetting
                    ? !!data.oldSchedule.lint_filter_replacement
                    : undefined,
                comment: data.oldSchedule.comment || '',
                reason: data.oldSchedule.reason
                    ? data.oldSchedule.reason
                    : ApplianceDeactivationReason.OTHER,
            };
        } else {
            const now = new Date();
            return {
                nextStart: now,
                durationHours: '9',
                repeatInterval: formatRepeatInterval(
                    DEFAULT_REPEAT_INTERVAL.type,
                    DEFAULT_REPEAT_INTERVAL.multiplier
                ),
                monthlyMinimumDay: String(getDefaultMonthlyMinimumDay(now)),
                enableApplianceAfterwards:
                    EnableApplianceAfterwards.RESTORE_PREVIOUS_STATE,
                lintFilterReplacement: data.showLintFilterSetting
                    ? false
                    : undefined,
                comment: '',
                reason: ApplianceDeactivationReason.TECHNICAL_DEFECT,
            };
        }
    }

    function getRepeatIntervalOptions(): RepeatInterval[] {
        const options: RepeatInterval[] = [
            { type: RepeatType.NONE, multiplier: undefined },
            { type: RepeatType.WEEKLY, multiplier: 1 },
            { type: RepeatType.WEEKLY, multiplier: 2 },
            { type: RepeatType.MONTHLY_SAME_WEEKDAY, multiplier: 1 },
        ];
        if (data.oldSchedule) {
            const oldInterval: RepeatInterval = {
                type: data.oldSchedule.repeat_type,
                multiplier: data.oldSchedule.repeat_multiplier,
            };
            if (
                !options.find(
                    (it) =>
                        formatRepeatInterval(it.type, it.multiplier) ===
                        formatRepeatInterval(
                            oldInterval.type,
                            oldInterval.multiplier
                        )
                )
            ) {
                options.push(oldInterval);
            }
        }
        return options;
    }

    function convertFormValues(
        formValues: InputFormData
    ): ApplianceMaintenanceScheduleDto {
        const { type: repeatType, multiplier: repeatMultiplier } =
            parseRepeatInterval(
                formValues.repeatInterval,
                repeatIntervalOptions
            );
        return {
            id: (data.oldSchedule && data.oldSchedule.id) || undefined,
            appliance_id: data.applianceId,
            next_start: formValues.nextStart.getTime(),
            duration: hoursToMillis(formValues.durationHours),
            repeat_type: repeatType,
            repeat_multiplier: repeatMultiplier,
            monthly_minimum_day: requiresMonthlyMinimumDay(repeatType)
                ? parseInt(formValues.monthlyMinimumDay)
                : undefined,
            enable_appliance_afterwards: formValues.enableApplianceAfterwards,
            lint_filter_replacement: formValues.lintFilterReplacement,
            comment: formValues.comment,
            reason: formValues.reason,
        };
    }
}

export default connect(
    (state: RootStore) => ({ manageApplianceStore: state.manageAppliance }),
    {
        close: closeModal,
        createMaintenanceSchedule,
        updateMaintenanceSchedule,
        resetActionErrors,
    }
)(UpsertMaintenanceSchedule);
