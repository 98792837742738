import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { LocationThreatLevel } from '../../store/location/locationReducer';
import * as yup from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';

import { updateLocationThreatLevel } from '../../store/manage-laundry-room/manageLaundryRoomActions';
import { DisplayedLaundryRoomInfo } from '../../forms/manage-appliance/LaundryRoomAnchorCard';
import Modal from 'react-bootstrap/Modal';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import { closeModal } from '../../store/modal/modalActions';

const THREAT_LEVEL_TO_TEXT: { [k in LocationThreatLevel]: string } = {
    [LocationThreatLevel.THREAT]: 'Bedroht (THREAT)',
    [LocationThreatLevel.NORMAL]: 'Normal (NORMAL)',
};

interface Props {
    laundryRoomStore: ManageLaundryRoomStore;
    data: { metadata?: DisplayedLaundryRoomInfo; locationId: number };
    updateLocationThreatLevel: (
        locationId: string,
        threatLevel: LocationThreatLevel
    ) => void;
    close: () => void;
}

interface FrequencyFormValues {
    laundryRoomId: string;
    threatLevel: string;
}

const schema: yup.ObjectSchema<FrequencyFormValues> = yup.object({
    laundryRoomId: yup.string().required(),
    threatLevel: yup.string().required(),
});

function EditLaundryRoomThreatLevel({
    laundryRoomStore,
    ...props
}: Readonly<Props>) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const isLoading = patchTriggered && laundryRoomStore.loading;

    useEffect(() => {
        if (
            patchTriggered &&
            !isLoading &&
            laundryRoomStore.errors.length === 0
        ) {
            close();
        }
    }, [patchTriggered, isLoading, laundryRoomStore.errors, close]);

    const laundryRoomInfo = props.data.metadata;
    if (!laundryRoomInfo) {
        return null;
    }

    const initialValues: FrequencyFormValues = {
        laundryRoomId: String(laundryRoomInfo.id),
        threatLevel: laundryRoomInfo.threatLevel || '',
    };

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Bedrohungsstufe ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Bedrohungsstufe für Waschraum (#{props.data.locationId}) setzen?
                Das setzt auch den internen, täglichen Zähler zurück.
                <Formik
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={schema}
                >
                    {renderForm}
                </Formik>
            </Modal.Body>
        </Fragment>
    );

    function handleSubmit(
        values: FrequencyFormValues,
        actions: FormikActions<FrequencyFormValues>
    ) {
        actions.setSubmitting(true);
        try {
            setPatchTriggered(true);
            props.updateLocationThreatLevel(
                values.laundryRoomId,
                values.threatLevel as LocationThreatLevel
            );
        } finally {
            actions.setSubmitting(false);
        }
    }

    function close() {
        props.close();
    }

    function renderForm({
        values,
        ...formProps
    }: FormikProps<FrequencyFormValues>) {
        const valueIsDifferent =
            laundryRoomInfo != null &&
            values.threatLevel !== laundryRoomInfo.threatLevel;
        const mayNotSubmit = !valueIsDifferent || formProps.isSubmitting;
        const buttonText = valueIsDifferent
            ? 'Bedrohungsstufe setzen'
            : 'Keine Änderung';
        return (
            <Form onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'laundryRoomId'}>
                    <Form.Label>Waschraum ID</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={values.laundryRoomId}
                        disabled
                    />
                </Form.Group>
                <Form.Group controlId={'threatLevel'}>
                    <Form.Label>Bedrohungsstufe</Form.Label>
                    <Form.Control
                        onChange={formProps.handleChange}
                        as={'select'}
                        value={values.threatLevel}
                    >
                        {Object.keys(THREAT_LEVEL_TO_TEXT).map((tl) => (
                            <option value={tl} key={tl}>
                                {
                                    THREAT_LEVEL_TO_TEXT[
                                        tl as LocationThreatLevel
                                    ]
                                }
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Button
                    type={'submit'}
                    disabled={mayNotSubmit}
                    variant={valueIsDifferent ? 'primary' : 'outline-secondary'}
                >
                    {buttonText}
                </Button>
            </Form>
        );
    }
}

export default connect(
    (store: RootStore) => ({ laundryRoomStore: store.manageLaundryRooms }),
    {
        updateLocationThreatLevel: updateLocationThreatLevel,
        close: closeModal,
    }
)(EditLaundryRoomThreatLevel);
