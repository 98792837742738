import React from 'react';
import styles from './UserInformationCard.module.css';
import {
    UserExtendedInformation,
    UserParallelReservationsState,
    UserType,
} from 'store/search-user/searchUserReducer';
import {
    translateLocationLifecycleAndHubStatus,
    translatePaymentMethodStatus,
    translateUserAccountState,
} from '../../user-information-table/UserInformationTable';
import { TRANSLATIONS_FOR_INVOICE_DELIVERY } from '../ChangeInvoiceDelivery';
import { KeyValueElement } from '../../common/KeyValueElement';
import { translateAllowPlainSepaMandateCreation } from '../ChangeAllowPlainSepaMandateCreation';
import { translateParallelReservationsState } from '../ChangeUserParallelReservationsModal';

interface Props {
    user: UserExtendedInformation;

    onEditLaundryRoom: () => void;
    onEditUserTrustLevel: () => void;
    onEditInvoiceDelivery: () => void;
    onEditAllowPlainSepaMandateCreation: () => void;
    onEditParallelReservations: () => void;
}

const USER_BACKGROUNDS = [
    { backgroundColor: '#7f7f7f' },
    { backgroundColor: '#00B3E6' },
    { backgroundColor: '#0c5460' },
];

function UserInformationCard({ user, ...props }: Props) {
    return (
        <div className={styles.card}>
            <div className={styles.leftColumn}>
                <div
                    className={styles.ersatzImage}
                    style={
                        USER_BACKGROUNDS[user.user_id % USER_BACKGROUNDS.length]
                    }
                >
                    {user.name.substring(0, 2)}
                </div>
                {user.b2b_user && <div className={styles.label}>B2B User</div>}

                <KeyValueElement name={'Name'}>{user.name}</KeyValueElement>
                <KeyValueElement name={'E-Mail'}>{user.email}</KeyValueElement>
                <KeyValueElement name={'Telefonnummer'}>
                    {user.phone_number || '-'}
                </KeyValueElement>
                <KeyValueElement name={'Customer ID'}>
                    {user.customer_id}
                </KeyValueElement>
            </div>
            <div className={styles.rightColumn}>
                <KeyValueElement name={'Account Status'}>
                    {translateUserAccountState(user.account_state)}
                </KeyValueElement>
                <KeyValueElement
                    name={'Vertrauensstufe'}
                    hasEditButton={true}
                    onEdit={handleUserTrustLevelEdit}
                >
                    {user.user_trust_level}
                </KeyValueElement>
                <KeyValueElement name={'Nutzertyp'}>
                    {translateUserType(user.user_type)}
                </KeyValueElement>

                <KeyValueElement name={'Zahlungsmethode'}>
                    {user.b2b_user
                        ? 'via Partner Gutschrift'
                        : user.payment_information}
                    <br />
                    Status:{' '}
                    {translatePaymentMethodStatus(user.payment_method_status)}
                </KeyValueElement>

                {!user.b2b_user && (
                    <KeyValueElement name={'Schulden'}>
                        {user.has_debt ? 'ja' : 'nein'}
                    </KeyValueElement>
                )}
                <KeyValueElement
                    name={'Waschraum'}
                    hasEditButton
                    onEdit={handleLaundryRoomEdit}
                >
                    {user.location.name}
                    <br />
                    {user.location.address}
                </KeyValueElement>
                <KeyValueElement name={'Waschraum-Status'}>
                    {translateLocationLifecycleAndHubStatus(
                        user.location.lifecycle_status,
                        user.hub_state
                    )}
                </KeyValueElement>
                <KeyValueElement
                    name={'Rechnungszustellung'}
                    hasEditButton
                    onEdit={handleInvoiceDeliveryEdit}
                >
                    {TRANSLATIONS_FOR_INVOICE_DELIVERY[
                        user.invoice_delivery_method
                    ] || '?'}
                </KeyValueElement>
                <KeyValueElement
                    name={'SEPA Mandat Erstellung'}
                    hasEditButton
                    onEdit={handleAllowPlainSepaMandateEdit}
                >
                    {translateAllowPlainSepaMandateCreation(
                        user.allow_plain_sepa_mandate_creation
                    )}
                </KeyValueElement>
                <KeyValueElement
                    name={'Gleichzeitige Reservierungen'}
                    hasEditButton
                    onEdit={handleEditParallelReservations}
                >
                    {translateParallelReservationsState(
                        user.parallel_reservations_state
                    )}
                    <br />
                    {user.parallel_reservations_state !==
                        UserParallelReservationsState.UNLIMITED &&
                        'Limit (für Normal): ' +
                            user.parallel_reservations_limit}
                </KeyValueElement>
            </div>
        </div>
    );

    function handleLaundryRoomEdit() {
        props.onEditLaundryRoom();
    }

    function handleUserTrustLevelEdit() {
        props.onEditUserTrustLevel();
    }

    function handleInvoiceDeliveryEdit() {
        props.onEditInvoiceDelivery();
    }

    function handleAllowPlainSepaMandateEdit() {
        props.onEditAllowPlainSepaMandateCreation();
    }

    function handleEditParallelReservations() {
        props.onEditParallelReservations();
    }
}

function translateUserType(userType: UserType) {
    switch (userType) {
        case UserType.APP:
            return 'App-Nutzer';
        case UserType.PHONE:
            return 'Telefon-Nutzer';
        default:
            return userType;
    }
}

export default UserInformationCard;
