import React, { Fragment, useEffect, useState } from 'react';
import {
    UserExtendedInformation,
    UserTrustLevel,
} from '../../store/search-user/searchUserReducer';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikProps } from 'formik';
import { changeUserTrustLevel } from '../../store/manage-user/manageUserActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    data: {
        userId: number;
    };

    searchResultsById: UserExtendedInformation[];
    errorTags: WeWashApiErrorTag[];
    isPatching: boolean;
    changeUserTrustLevel: (
        userId: number,
        userTrustLevel: UserTrustLevel
    ) => void;
    close: () => void;
}

interface Data {
    userId: number;
    userTrustLevel: UserTrustLevel;
}

export const USER_VERIFICATION_STATES: UserTrustLevel[] = [
    UserTrustLevel.JAIL,
    UserTrustLevel.RISK,
    UserTrustLevel.NORMAL,
    UserTrustLevel.VERIFIED,
];

export const TRANSLATIONS_FOR_USER_TRUST_LEVEL: {
    [key in UserTrustLevel]: string;
} = {
    [UserTrustLevel.RISK]: 'Risiko',
    [UserTrustLevel.JAIL]: 'Gesperrt',
    [UserTrustLevel.VERIFIED]: 'Verifiziert',
    [UserTrustLevel.NORMAL]: 'Normal',
};

function ChangeUserTrustLevel(props: Readonly<Props>) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const information: UserExtendedInformation | undefined =
        props.searchResultsById.find((it) => it.user_id === props.data.userId);

    const initialValues: Data = {
        userId: props.data.userId,
        userTrustLevel:
            information && information.user_trust_level
                ? information.user_trust_level
                : UserTrustLevel.NORMAL,
    };
    const allowedValues = USER_VERIFICATION_STATES;

    useEffect(() => {
        if (patchTriggered && !props.isPatching) {
            if (props.errorTags.length > 0) {
                setPatchTriggered(false);
            } else {
                props.close();
            }
        }
    }, [patchTriggered, props.isPatching, props.errorTags, props.close]);

    if (!information) {
        return (
            <Button
                className={'mr-2'}
                type="button"
                onClick={handleCancel}
                variant={'outline-secondary'}
            >
                ABBRECHEN
            </Button>
        );
    }
    const email = information.email;
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Vertrauensstufe ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                    {renderForm}
                </Formik>
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<Data>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Row className={'ml-3 mb-3'}>
                    Die Vertrauensstufe des Nutzers {email} ändern.
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Form.Group controlId={'userTrustLevel'}>
                        <Form.Label>Vertrauensstufe:</Form.Label>
                        <Form.Control
                            as={'select'}
                            onChange={formProps.handleChange}
                            value={formProps.values.userTrustLevel}
                        >
                            {allowedValues.map((it) => (
                                <option key={it} value={it}>
                                    {TRANSLATIONS_FOR_USER_TRUST_LEVEL[it]}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Button
                        className={'mr-2'}
                        type="button"
                        onClick={handleCancel}
                        variant={'outline-secondary'}
                    >
                        ABBRECHEN
                    </Button>
                    <Button type={'submit'} disabled={formProps.isSubmitting}>
                        VERTRAUENSSTUFE ÄNDERN
                    </Button>
                </Row>
                <ErrorFeedback apiErrors={props.errorTags} />
            </Form>
        );
    }

    async function handleSubmit(values: Data) {
        props.changeUserTrustLevel(values.userId, values.userTrustLevel);
        setPatchTriggered(true);
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (store: RootStore) => ({
        searchResultsById: store.searchUser.resultsById,
        errorTags: store.manageUser.patchErrors,
        isPatching: store.manageUser.patching,
    }),
    { close: closeModal, changeUserTrustLevel: changeUserTrustLevel }
)(ChangeUserTrustLevel);
